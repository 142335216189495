import { Controller } from "@hotwired/stimulus"
import autosize from "autosize"

// Connects to data-controller="new-message"
export default class extends Controller {
  static targets = ["messageContent", "send", "mic"]

  connect() {
    // When the controller is initialized, immediately toggle button visibility:
    this.toggle()
  }

  toggle() {
    // Check if there's any text in the messageContent textarea
    const hasText = this.messageContentTarget.value.trim().length > 0

    // If there's text, show Send and hide Mic
    this.sendTarget.classList.toggle("d-none", !hasText)
    this.micTarget.classList.toggle("d-none", hasText)
  }

  reset() {
    this.element.reset();
    autosize.update(this.messageContentTarget);
    this.toggle();
  }

  submitForm() {
    this.element.requestSubmit();
  }
}
