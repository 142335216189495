export function initGoogleMaps() {
    return new Promise((resolve, reject) => {
        ((g) => {
        var h,
            a,
            k,
            p = "The Google Maps JavaScript API",
            c = "google",
            l = "importLibrary",
            q = "__ib__",
            m = document,
            b = window;
        b = b[c] || (b[c] = {});
        var d = b.maps || (b.maps = {}),
            r = new Set(),
            e = new URLSearchParams(),
            u = () =>
                h ||
                (h = new Promise((f, n) => {
                    a = m.createElement("script");
                    e.set("libraries", [...r] + "");
                    for (k in g)
                    e.set(
                        k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
                        g[k],
                    );
                    e.set("callback", c + ".maps." + q);
                    a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
                    d[q] = f;
                    a.onerror = () => (h = n(Error(p + " could not load.")));
                    a.nonce = m.querySelector("script[nonce]")?.nonce || "";
                    m.head.append(a);
                }));
        d[l]
            ? console.warn(p + " only loads once. Ignoring:", g)
            : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
        u()
            .then(() => resolve(d))
            .catch(reject);
        })({
        key: "AIzaSyB9qFVE2y-pmaoa1xc_gereM0QIAjOxTfs",
        v: "weekly",
        // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
        // Add other bootstrap parameters as needed, using camel case.
        });
    });
}

export function formatMapBoundsParams(map) {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast(); // Northeast corner
  const sw = bounds.getSouthWest(); // Southwest corner

  return `north=${ne.lat()}&east=${ne.lng()}&south=${sw.lat()}&west=${sw.lng()}`;
}

export function clearMarkers(markerCollection) {
  markerCollection.forEach(marker => {
    marker.setMap(null);
  });

  markerCollection.clear();
}

export function calculateBoundsDifference(prevBounds, newBounds) {
  const getLatLngDiff = (prev, next) => {
    return Math.abs(prev.lat() - next.lat()) + Math.abs(prev.lng() - next.lng());
  };

  const swPrev = prevBounds.getSouthWest();
  const nePrev = prevBounds.getNorthEast();
  const swNew = newBounds.getSouthWest();
  const neNew = newBounds.getNorthEast();

  return getLatLngDiff(swPrev, swNew) + getLatLngDiff(nePrev, neNew);
}

export function retrieveListingsData(listingsContainer) {
  // Read zoom and center from the container's data attributes
  const zoom = parseInt(listingsContainer.dataset.zoom, 10);
  const center = {
    lat: parseFloat(listingsContainer.dataset.centerLat),
    lng: parseFloat(listingsContainer.dataset.centerLng),
  };

  const autoCenter = listingsContainer.dataset.autoCenter === "true";
  const markerTemplate = listingsContainer.dataset.markerTemplate;

  // Collect all listings and map them into { id, lat, lng } objects
  const listings = listingsContainer.querySelectorAll(".listing");
  const data = Array.from(listings).map(listing => {
    return {
      id: parseInt(listing.dataset.id, 10),
      lat: parseFloat(listing.dataset.lat),
      lng: parseFloat(listing.dataset.lng),
      price: listing.dataset.price,
      savedClass: listing.dataset.savedClass,
    };
  });

  // Return an object containing zoom, center, and data
  return { zoom, center, autoCenter, markerTemplate, data };
}