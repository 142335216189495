import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat-widget"
export default class extends Controller {
  static targets = ["launcher", "panel", "closeButton"]

  connect() {
    this.conversationLoadedBound = this.conversationLoaded.bind(this);
    window.addEventListener("conversation:loaded", this.conversationLoadedBound);
  }

  disconnect() {
    window.removeEventListener("conversation:loaded", this.conversationLoadedBound);
  }

  open() {
    this.panelTarget.classList.remove("d-none");
    this.launcherTarget.classList.add("d-none");
    this.closeButtonTarget.classList.remove("d-none");
  }

  close() {
    this.panelTarget.classList.add("d-none");
    this.launcherTarget.classList.remove("d-none");
    this.closeButtonTarget.classList.add("d-none");
  }

  conversationLoaded() {
    this.open();
  }
}
