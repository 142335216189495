export function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top <
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.bottom > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.right > 0
    );
}

export function createDomNodeFromHtml(htmlString) {
  if (!htmlString) {  // Check if htmlString is nil, null, or undefined
    return null; // Return null if the input is not a valid string
  }

  const div = document.createElement("div");
  div.innerHTML = htmlString.trim(); // Make sure the HTML is trimmed to avoid unwanted text nodes
  return div.firstChild; // Return the first child element of the created div
}