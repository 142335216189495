import { Controller } from "@hotwired/stimulus"
import Plyr from "plyr";

export default class extends Controller {
  connect() {
    this.player = new Plyr(this.element.querySelector('audio'));
  }

  disconnect() {
    // Destroy the Plyr instance when the controller is disconnected
    if (this.player) {
      this.player.destroy();
    }
  }
}
