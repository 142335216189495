import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="population-chart"
export default class extends Controller {
  static values = { males: Number, females: Number, malePercentage: Number, femalePercentage: Number };
  static targets = ["chart"];

  async connect() {
    const { Chart, registerables } = await import("chart.js");
    Chart.register(...registerables);

    const ctx = this.chartTarget.getContext("2d");

    const data = {
      labels: ['Males', 'Females'],
      datasets: [{
        data: [this.malesValue, this.femalesValue],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF4355', '#2386C8']
      }]
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const percentage = tooltipItem.label === 'Males'
                ? this.malePercentageValue
                : this.femalePercentageValue;
              return `${tooltipItem.label}: ${tooltipItem.raw} (${percentage.toFixed(2)}%)`;
            }
          }
        }
      }
    };

    new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: options
    });
  }
}