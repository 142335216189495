import bootstrap from "../bootstrap";
import { fetchWithTurboStream } from "./fetchUtils";

export function getOrInitializeModal() {
  // Get the modal element by its ID
  const modalElement = document.getElementById('modal');

  // Check if the modal element exists
  if (!modalElement) {
    console.error(`Modal not found.`);
    return null;
  }

  // If the modal has already been instantiated, return the existing instance
  let modalInstance = bootstrap.Modal.getInstance(modalElement);

  // If the modal instance does not exist, instantiate it
  if (!modalInstance) {
    modalInstance = new bootstrap.Modal(modalElement);
  }

  // Return the modal instance
  return modalInstance;
}

// Utility to close all open modals
export function removeModal() {
  const modal = document.getElementById('modal');

  if (modal) {
    if (modal.classList.contains('show')) {
      let modalInstance = bootstrap.Modal.getInstance(modal);
      if (modalInstance) {
        modal.classList.remove("fade");
        modalInstance._backdrop._config.isAnimated = false;
        modalInstance.hide();
        modalInstance.dispose();
      }
    }

    modal.remove();

    const triggerElement = document.querySelector('[data-modal-triggered="true"]');

    if (triggerElement) {
      delete triggerElement.dataset.modalTriggered;
      delete triggerElement.dataset.bsToggle;
      delete triggerElement.dataset.bsTarget;
    }
  }
}

// Utility to open a specific modal by ID
export function openModal() {
  const modalElement = document.getElementById("modal");

  const modalInstance = getOrInitializeModal();

  if (modalElement) {
    if (!modalElement.classList.contains("show")) {
      const triggerElement = document.querySelector('[data-modal-triggered="true"]');

      if (triggerElement) {
        triggerElement.dataset.bsTarget = '#modal';
        triggerElement.dataset.bsToggle = 'modal';

        triggerElement.click();
      } else {        
        modalInstance.show();
      }
    }
  }
}

export async function fetchAndDisplayModal(modalType, params = {}) {
  const url = new URL(
    `/${window.currentLocale}/show_modal/${modalType}`,
    window.location.origin,
  );
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );

  await fetchWithTurboStream(url.toString(), { method: "GET" });
}
