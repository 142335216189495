import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-price-fields"
export default class extends Controller {
  static targets = ["toggleCheckbox", "priceField", "error"]

  connect() {
    // Run once on page load
    this.toggle()
  }

  toggle() {
    // For each priceField, check if the corresponding checkbox is checked
    this.priceFieldTargets.forEach(priceField => {
      const fieldType = priceField.dataset.type // "rent" or "sale"

      // Find the corresponding checkbox from toggleCheckboxTargets
      const checkbox = this.toggleCheckboxTargets.find(
        cb => cb.dataset.type === fieldType
      )

      const isChecked = checkbox && checkbox.checked;

      priceField.classList.toggle('d-none', !isChecked);

      const input = priceField.querySelector("input");
    
      if (input) {
        if (isChecked) {
          input.setAttribute("required", true);
        } else {
          input.removeAttribute("required");
          input.value = '';
        }
      }
    })

    const isAnyChecked = this.toggleCheckboxTargets.some(cb => cb.checked)
    if (isAnyChecked) {
      this.errorTarget.classList.add("d-none")
    }
  }
}
