import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="swiper"
export default class extends Controller {
  static Swiper;
  static Navigation;
  static Pagination;

  static values = {
    mode: { type: String, default: "listing" },
    initializeEnd: { type: Boolean, default: false },
    buttonSuffix: String
  };

  async connect() {
    if (!this.constructor.Swiper) {
      const { default: Swiper } = await import("swiper");
      const { Navigation, Pagination } = await import("swiper/modules");

      this.constructor.Swiper = Swiper;
      this.constructor.Navigation = Navigation;
      this.constructor.Pagination = Pagination;

      Swiper.use([Navigation, Pagination]);
    }

    let spaceBetween, breakpoints, initializeSlide;

    if (this.modeValue === "container-fluid") {
      spaceBetween = 32;
      breakpoints = {
        1400: {
          slidesPerView: 4.2,
          navigation: {
            enabled: true
          }
        },
        992: {
          slidesPerView: 3.2,
          navigation: {
            enabled: true
          }
        },
        768: {
          slidesPerView: 2.2,
          navigation: {
            enabled: true
          }
        },
        576: {
          slidesPerView: 2.2,
        },
      };
    } else if (this.modeValue === "container") {
      spaceBetween = 32;
      breakpoints = {
        1400: {
          slidesPerView: 3,
          navigation: {
            enabled: true
          }
        },
        768: {
          slidesPerView: 2,
          navigation: {
            enabled: true
          }
        },
      };
    } else {
      spaceBetween = 0;
      breakpoints = {
        768: {
          navigation: {
            enabled: true
          }
        }
      }
    }

    if (this.initializeEndValue) {
      initializeSlide = this.element.querySelectorAll('.swiper-slide').length - 1;
    } else {
      initializeSlide = 0;
    }

    const buttonSuffix = this.hasButtonSuffixValue ? `-${this.buttonSuffixValue}` : '';

    this.swiper = new this.constructor.Swiper(this.element, {
      lazy: true,
      slidesPerView: 1,
      spaceBetween: spaceBetween,
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
      navigation: {
        nextEl: `.swiper-button-next${buttonSuffix}`,
        prevEl: `.swiper-button-prev${buttonSuffix}`,
        enabled: false
      },
      breakpoints: breakpoints,
      initialSlide: initializeSlide
    });
  }

  disconnect() {
    if (this.swiper && !this.swiper.destroyed) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }
}
