import { Controller } from "@hotwired/stimulus"
import { scrollMessagesToBottom } from "../utils/chat";

// Connects to data-controller="message"
export default class extends Controller {
  connect() {
    this.media = this.element.querySelector('img, video');

    this.handleMediaLoadEvents();
    scrollMessagesToBottom();
  }

  handleMediaLoadEvents() {
    if (this.media) {
      const tagName = this.media.tagName.toLowerCase();
      if (tagName === 'img') {
        this.media.addEventListener('load', () => {
          scrollMessagesToBottom();
        });
      } else if (tagName === 'video') {
        this.media.addEventListener('loadeddata', () => {
          scrollMessagesToBottom();
        });
      }
    }
  }
}
