import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="history"
export default class extends Controller {
  static values = { path: String }

  // This method is triggered by a click or any event you choose
  connect() {
    history.pushState({}, "", this.pathValue);
    
    window.dispatchEvent(new CustomEvent("historyUpdate", {
      detail: {
        path: this.pathValue
      }
    }));
  }
}
