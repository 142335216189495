import { Controller } from "@hotwired/stimulus";
import { fetchWithTurboStream } from "../utils/fetchUtils";
import { isMobile } from "../utils/utils";

// Connects to data-controller="content-loader"
export default class extends Controller {
  static values = { path: String };

  connect() {
    this.loadMoreBound = this.loadMore.bind(this); // Bind the method to keep the context
    window.addEventListener("scroll", this.loadMoreBound);
  }

  disconnect() {
    window.removeEventListener("scroll", this.loadMoreBound); // Clean up
  }

  async loadMore() {
    if (isMobile() && !this.loading && this.nearBottomOfPage()) {
      this.loading = true;
      this.element.classList.remove("d-none");
      await fetchWithTurboStream(this.pathValue);
      this.element.remove();
    }
  }

  nearBottomOfPage() {
    return (
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight - 1000
    );
  }
}
