import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="text-area"
export default class extends Controller {
  static targets = ["textArea", "unfoldMoreIcon", "unfoldLessIcon"];

  toggleSize() {
    const minHeight = this.textAreaTarget.style.minHeight;
    this.unfoldMoreIconTarget.classList.toggle('d-none');
    this.unfoldLessIconTarget.classList.toggle('d-none');
    
    if (minHeight === "206px" || minHeight === "") {
      this.textAreaTarget.style.minHeight = "800px"; // Expanded size
    } else {
      this.textAreaTarget.style.minHeight = "206px"; // Original size
    }
  }
}
