import { Controller } from "@hotwired/stimulus"
import { fetchWithTurboStream } from "../utils/fetchUtils";
import { removeModal } from "../utils/modal_helpers";

// Connects to data-controller="modal-trigger"
export default class extends Controller {
  static values = {
    path: String
  }
  
  click() {
    if (!this.element.dataset.modalTriggered) {
      removeModal();
      this.element.dataset.modalTriggered = 'true';
      fetchWithTurboStream(this.pathValue);
    }
  }
}
