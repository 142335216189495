import { Controller } from "@hotwired/stimulus"
import { fetchWithTurboStream } from "../utils/fetchUtils"

// Connects to data-controller="conversation"
export default class extends Controller {
  static values = {
    id: Number
  }

  connect() {
    // Bind a version of handleBeforeRender that has `this` = the controller
    // Store the bound function so we can unbind it later in disconnect()
    this.boundHandleBeforeRender = this.handleBeforeRender.bind(this)

    // Listen at the document level for all turbo:before-stream-render events
    document.addEventListener("turbo:before-stream-render", this.boundHandleBeforeRender);

    window.dispatchEvent(new CustomEvent("conversation:loaded"));
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    document.removeEventListener("turbo:before-stream-render", this.boundHandleBeforeRender)
  }

  // This will be called for every turbo:before-stream-render event on the page
  handleBeforeRender(event) {
    const turboStreamEl = event.target

    const action = turboStreamEl.getAttribute("action")
    const target = turboStreamEl.getAttribute("target")

    if (action === "append" && target === "messages") {
      this.markConversationAsRead()
    }
  }

  markConversationAsRead() {
    fetchWithTurboStream(`/conversations/${this.idValue}/read`, { method: "POST" });
  }
}
