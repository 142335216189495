import { Controller } from "@hotwired/stimulus";
import { isMobile } from "../utils/utils";

// Connects to data-controller="scroll"
export default class extends Controller {
  connect() {
    this.lastScrollTop = 0;

    if (isMobile()) {
      window.addEventListener("scroll", this.handleScroll.bind(this));
    }
  }

  disconnect() {
    if (isMobile()) {
      window.removeEventListener("scroll", this.handleScroll.bind(this));
    }
  }

  handleScroll() {
    const currentScroll = window.scrollY || document.documentElement.scrollTop;

    if (currentScroll > this.lastScrollTop) {
      this.element.classList.replace("show-navbar", "hide-navbar");
    } else {
      this.element.classList.replace("hide-navbar", "show-navbar");
    }

    this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  }
}
