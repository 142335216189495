import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static values = { hashShort: String, autoCenter: Boolean };

  connect() {
    window.scrollTo(0, 0);
    
    const newURL = this.hashShortValue
      ? `${window.location.pathname}?h=${this.hashShortValue}`
      : window.location.pathname;

    history.pushState({}, "", newURL);

    this.dispatchSearchEvent();
  }

  dispatchSearchEvent() {
    const event = new CustomEvent("newSearch", {
      bubbles: true,
    });

    window.dispatchEvent(event);
  }
}
