import { Controller } from "@hotwired/stimulus";
import { toggleButtonState, updateButtonState } from "../utils/buttons";
import { fetchWithTurboStream } from "../utils/fetchUtils";

// Connects to data-controller="verify-otp"
export default class extends Controller {
  static targets = ["phoneNumber", "otpInput", "submitButton"];

  static values = {
    errorMessage: String,
    hideOtpField: Boolean,
  };

  connect() {
    this.form = document.getElementById('verify-otp-form');

    if (this.form) {
      this.button = this.form.querySelector("button");
    } else {
      this.button = document.getElementById("verify-otp-button");
    }

    if (this.hasErrorMessageValue) {
      this.handleError();
    } else if (this.hideOtpFieldValue) {
      this.handleHideOtpField();
    }
  }

  disableButton() {
    updateButtonState(this.submitButtonTarget, true);
  }

  async autoVerifyOtp() {
    const otp = this.otpInputTarget.value;
    if (otp.length === 6 && /^\d{6}$/.test(otp)) {
      this.disableButton();
      this.verifyOtp();
    }
  }

  async verifyOtp() {
    const data = {
      user: { phone_number: this.phoneNumberTarget.value },
      otp_input: this.otpInputTarget.value,
    };

    const body = JSON.stringify(data);
    const url = `/${window.currentLocale}/verify_otp`;

    await fetchWithTurboStream(url, {
      method: "POST",
      body: body,
    });
  }

  handleError() {
    if (this.form) {
      this.form.querySelectorAll('input[name^="otp_code"]').forEach((input) => {
        input.classList.add("border-danger");
      });

      toggleButtonState(this.button, false);
    } else {
      updateButtonState(this.button, false);
    }
  }

  handleHideOtpField() {
    document.getElementById("otp-field-container").classList.add("d-none");
    const userPhoneNumber = document.getElementById("user_phone_number");
    userPhoneNumber.readOnly = true;
    document.getElementById("otp-sent-text").classList.add("d-none");
    document.getElementById("otp-verified-text").classList.remove("d-none");
    const errorElements = document.querySelectorAll(
      '[id^="phone-number-error"]',
    );

    // Set the text content of each matched element to an empty string or any other message
    errorElements.forEach((element) => {
      element.textContent = ""; // Or set to any default or error message as needed
    });
  }
}
