import { Controller } from "@hotwired/stimulus"
import { fetchWithJson } from "../utils/fetchUtils";

// Connects to data-controller="map-toggle-button"
export default class extends Controller {
  static targets = ["mapSpan", "listSpan"];

  connect() {
    this.bottomNavbar = document.getElementById('bottom-navbar');
    this.bottomNavbarContainer = document.getElementById('bottom-navbar-container');
    this.map = document.getElementById("map");
    this.header = document.querySelector('header');
  }

  toggle() {
    const listings = document.getElementById("listings");
    // Switch to "list" mode:
    this.mapSpanTarget.classList.toggle("d-none");
    this.listSpanTarget.classList.toggle("d-none");

    // Hide the listings container and show the map container
    listings.classList.toggle("d-none");
    this.map.classList.toggle("d-none");
    this.bottomNavbar.classList.toggle('d-none');

    const viewPreference = listings.classList.contains("d-none") ? 'map_view' : 'list_view';
    this.updateViewPreference(viewPreference);

    if (this.mapSpanTarget.classList.contains("d-none")) {
      this.header.classList.replace('hide-navbar', 'show-navbar');

      this.map.dispatchEvent(new CustomEvent("loadMap", { bubbles: true }));
      document.body.classList.replace('vh-md-100', 'dvh-100');
      this.bottomNavbarContainer.classList.replace('sticky-bottom', 'fixed-bottom');
    } else {
      document.body.classList.replace('dvh-100', 'vh-md-100');
      this.bottomNavbarContainer.classList.replace('fixed-bottom', 'sticky-bottom');
    }
    
  }

  updateViewPreference(view) {
    fetchWithJson('/users/update_view_preference', {
      method: 'PATCH',
      body: JSON.stringify({ view_preference: view })
    })
  }
}