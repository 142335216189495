import { Controller } from "@hotwired/stimulus";
import { fetchWithTurboStream } from "../utils/fetchUtils";
import { isMobile } from "../utils/utils";

// Connects to data-controller="autocomplete"
export default class extends Controller {
  static targets = ["input", "separator", "results", "clearButton", "hidden", "loading"];
  static values = { context: String };

  connect() {
    this.selectedResultIndex = -1;

    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.handleClickOutside);

    this.debounceTimeout = null;
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);

    clearTimeout(this.debounceTimeout);
  }

  handleFocus() {
    this.search();
  }

  handleInput() {
    this.prepareForSearch();

    // Implement debouncing: clear previous timeout and set a new one
    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => {
      this.search();
    }, 300);
  }

  async search() {
    this.prepareForSearch();

    if (isMobile()) {
      document.body.classList.add("overflow-hidden");
    }

    await fetchWithTurboStream(
      `/${window.currentLocale}/data/autocomplete?query=${encodeURIComponent(this.query)}&id=${this.resultsTarget.id}&context=${this.contextValue}`,
      { method: "GET" },
    );

    this.showResults();
  }

  navigate(e) {
    let results = this.resultsTarget.querySelectorAll("button");
    if (!results.length) return;

    switch (e.key) {
      case "ArrowDown":
        this.selectedResultIndex =
          (this.selectedResultIndex + 1) % results.length;
        this.highlightResult(results);
        break;

      case "ArrowUp":
        if (this.selectedResultIndex <= 0)
          this.selectedResultIndex = results.length;
        this.selectedResultIndex =
          (this.selectedResultIndex - 1) % results.length;
        this.highlightResult(results);
        break;

      case "Enter":
        if (this.selectedResultIndex > -1) {
          e.preventDefault(); // Prevent form submission
          results[this.selectedResultIndex].click();
        }
        break;
    }
  }

  highlightResult(results) {
    results.forEach((result, index) => {
      result.classList.toggle(
        "bg-body-tertiary",
        index === this.selectedResultIndex,
      );
    });
  }

  handleClickOutside(event) {
    if (
      !this.element.contains(event.target) &&
      !event.target.classList.contains("autocomplete-trigger")
    ) {
      this.closeOverlay();
    }
  }

  select(event) {
    const value = event.currentTarget.dataset.value;
    this.closeOverlay();
    this.query = value;

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = event.currentTarget.dataset.id;
    }
  }

  clearInput() {
    this.query = "";

    this.search();
    this.inputTarget.focus();

    const mapElement = document.getElementById('map');
    if (mapElement) {
      const event = new CustomEvent('searchCleared', {
        bubbles: true,
      });

      mapElement.dispatchEvent(event);
    }
  }

  closeOverlay() {
    this.element.classList.remove("is-active");
    if (isMobile()) {
      document.body.classList.remove("overflow-hidden");
    }
    this.clearResults();
  }

  clearResults() {
    if (this.hasResultsTarget) {
      this.resultsTarget.innerHTML = "";
      this.resultsTarget.classList.add('d-none');
      this.toggleSeparator(false);
    }
  }

  get query() {
    return this.inputTarget.value.trim();  // Encapsulate trimming logic
  }

  set query(value) {
    this.inputTarget.value = value;
    this.toggleClearButton();
  }

  toggleClearButton() {
    if (this.hasClearButtonTarget) {
      this.clearButtonTarget.classList.toggle("d-none", this.query.length === 0)
    }
  }

  toggleSeparator(shouldShow) {
    if (this.hasSeparatorTarget) {
      this.separatorTarget.classList.toggle("d-none", !shouldShow)
    }
  }

  prepareForSearch() {
    this.toggleClearButton(true);
    this.loadingTarget.classList.remove("d-none");
    this.toggleSeparator(true);
    this.resultsTarget.classList.add("d-none");
    this.element.classList.add("is-active");
  }

  showResults() {
    this.loadingTarget.classList.add("d-none");
    this.toggleSeparator(true);
    this.resultsTarget.classList.remove("d-none");
  }
}
