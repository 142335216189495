import { Controller } from "@hotwired/stimulus";
import { fetchWithTurboStream } from "../utils/fetchUtils";

export default class extends Controller {
  static targets = ["submitButton", "buttonText", "spinner", "form"];
  static values = {
    path: String,
  };

  connect() {
    this.fetchCount();

    this.debounceTimeout = null;
  }

  disconnect() {
    clearTimeout(this.debounceTimeout);
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  updateCount() {
    // Show loading indicator on the submit button
    this.buttonTextTarget.classList.add("invisible");
    this.spinnerTarget.classList.remove("d-none");

    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => {
      this.fetchCount();
    }, 300);
  }

  async fetchCount() {
    const form = this.element.querySelector("form");
    const formData = new FormData(form);
    const params = new URLSearchParams(formData).toString();
    const url = `${this.pathValue}?${params}`;

    await fetchWithTurboStream(url, { method: "GET" });
    this.spinnerTarget.classList.add("d-none");
    this.buttonTextTarget.classList.remove("invisible");
  }

  clearAll(event) {
    const url = event.currentTarget.getAttribute('href');
    fetchWithTurboStream(url);
  }
}
