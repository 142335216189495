import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slider-histogram"
export default class extends Controller {
  static values = {
    histogramData: Array,  // Accepts the histogram data as an array
  };

  connect() {
    this.updateHeights();
  }

  updateHeights() {
    const histogram = document.getElementById('slider-histogram');
    // Loop through the histogram bars
    this.histogramDataValue.forEach(bucket => {
      const bucketNumber = bucket.bucket_number;
      const barElement = histogram.querySelector(`[data-bucket-number="${bucketNumber}"]`);

      if (barElement) {
        barElement.style.height = `${bucket.height}px`;
      }
    });
  }
}
