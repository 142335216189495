// Entry point for the build script in your package.json

import "@hotwired/turbo-rails";
import "./controllers";

import { t } from "./i18n";

Turbo.setConfirmMethod(async (message) => {
  const { default: Swal } = await import("sweetalert2");

  const result = await Swal.fire({
    title: t("are_you_sure"),
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: t("yes"),
    cancelButtonText: t("no"),
    reverseButtons: true,
  });

  return result.isConfirmed;
});

import "./public.js";
