import { Controller } from "@hotwired/stimulus"
import WaveSurfer from "wavesurfer.js"
import { scrollMessagesToBottom } from "../utils/chat"

// Connects to data-controller="waveform"
export default class extends Controller {
  static targets = ["waveform", "elapsedTime", "playIcon", "pauseIcon"]

  connect() {
    // Retrieve the audio URL from the data attribute
    this.audioUrl = this.element.dataset.audioUrl
    
    // Initialize WaveSurfer
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformTarget,
      waveColor: '#cccccc',
      progressColor: '#333333',
      barWidth: 2,
      barRadius: 2,
      backend: 'WebAudio',
      height: 24,
      width: 200,
    })

    // Load the audio
    this.wavesurfer.load(this.audioUrl);

    this.wavesurfer.on("ready", () => {
      scrollMessagesToBottom();
    })

    // Update elapsed time
    this.wavesurfer.on('audioprocess', () => {
      // Current time in seconds
      let currentTime = this.wavesurfer.getCurrentTime()
      this.elapsedTimeTarget.textContent = this.formatTime(currentTime)
    })

    // Reset time display when audio ends
    this.wavesurfer.on('finish', () => {
      this.updatePlayButton();
      this.elapsedTimeTarget.textContent = this.formatTime(0);
    })
  }

  disconnect() {
    // Clean up WaveSurfer instance when controller is removed
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
  }

  // Called on click of playBtn
  togglePlay() {
    this.wavesurfer.playPause();
    this.updatePlayButton();
  }

  updatePlayButton() {
    const isPlaying = this.wavesurfer.isPlaying();
    this.playIconTarget.classList.toggle('d-none', isPlaying);
    this.pauseIconTarget.classList.toggle('d-none', !isPlaying);
  }

  formatTime(seconds) {
    // Convert seconds to mm:ss
    let min = Math.floor(seconds / 60)
    let sec = Math.floor(seconds % 60)
    return `${min}:${sec.toString().padStart(2, '0')}`
  }
}
