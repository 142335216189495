import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="audio-record"
export default class extends Controller {
  static targets = ["micIcon", "stopIcon", "mediaInput"] // optional

  connect() {
    this.isRecording = false
    this.stream = null
    this.recorder = null
    this.audioChunks = []
  }

  async toggle() {
    // if currently recording, stop
    if (this.isRecording) {
      this.stopRecording()
    } else {
      // otherwise, start
      await this.startRecording()
    }

    // Toggle icon visibility
    this.isRecording = !this.isRecording
    this.updateIcons()
  }

  async startRecording() {
    try {
      // Request microphone access
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      this.recorder = new MediaRecorder(this.stream)

      this.audioChunks = [] // reset

      // On data available, push chunks
      this.recorder.ondataavailable = event => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data)
        }
      }

      // Start recording
      this.recorder.start()
    } catch (error) {
      console.error("Error accessing microphone:", error)
    }
  }

  stopRecording() {
    if (!this.recorder || this.recorder.state !== "recording") {
      return
    }

    this.recorder.stop()

    // On stop, we have our final Blob
    this.recorder.onstop = () => {
      // Combine all chunks into a single Blob
      const audioBlob = new Blob(this.audioChunks, { type: this.recorder.mimeType });

      const file = new File([audioBlob], "voice_note.webm", { type: audioBlob.type });
      
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      this.mediaInputTarget.files = dataTransfer.files

      // Cleanup
      this.stream.getTracks().forEach(track => track.stop())

      this.element.requestSubmit();
    }
  }

  updateIcons() {
    if (!this.hasMicIconTarget || !this.hasStopIconTarget) return

    if (this.isRecording) {
      this.micIconTarget.classList.add("d-none")
      this.stopIconTarget.classList.remove("d-none")
    } else {
      this.micIconTarget.classList.remove("d-none")
      this.stopIconTarget.classList.add("d-none")
    }
  }
}
