import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="image-input"
export default class extends Controller {
  static targets = ["image", "fileInput"];
  static values = { url: String };

  // Trigger file input dialog
  triggerFileInput(event) {
    event.preventDefault();
    this.element.querySelector('input[type="file"]').click();
  }

  // Handle photo upload
  async uploadPhoto(event) {
    const file = event.target.files[0];
    if (!file) return;

    if (!this.urlValue) {
      // If no URL is provided, update the image directly with the selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imageTarget.src = reader.result;
      };
      reader.readAsDataURL(file);  // Convert file to a temporary image URL (base64)
      return; // Exit the function early since no upload request is needed
    }

    const formData = new FormData();
    formData.append("user[image]", file);

    try {
      const response = await fetch(this.urlValue, {
        method: "PATCH",
        body: formData,
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const json = await response.json();
        // Update the photo dynamically
        this.imageTarget.src = json.image_url;
      } else {
        alert("Error uploading photo");
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  }
}
