import { Controller } from "@hotwired/stimulus";
import { calculateBoundsDifference, clearMarkers, formatMapBoundsParams, initGoogleMaps, retrieveListingsData } from "../utils/map";
import { createDomNodeFromHtml, isInViewport } from "../utils/dom";
import { fetchWithTurboStream } from "../utils/fetchUtils";
import { isMobile } from "../utils/utils";

// Connects to data-controller="map"
export default class extends Controller {
  static targets = [
    "map",
    "schoolsCheckbox",
    "selectSpan",
    "deselectSpan"
  ];
  static values = {
    markersPath: String,
    addEventListeners: Boolean
  };

  connect() {
    this.listingMarkers = new Map();
    this.schoolMarkers = new Map();

    this.isMobile = isMobile();

    this.updateMarkers = this.updateMarkers.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this); 
    this.handleMapInteraction = this.handleMapInteraction.bind(this);
    this.handleResize = this.handleResize.bind(this); 
    this._onFavouriteToggled = this._onFavouriteToggled.bind(this)

    if (!isInViewport(this.mapTarget)) {
      this.mapLoaded = false;
      window.addEventListener("scroll", this.handleScroll, { passive: true });
    } else {
      this.initializeMap();
    }

    window.addEventListener("newSearch", this.updateMarkers);
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("favouriteToggled", this._onFavouriteToggled);
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("newSearch", this.updateMarkers);
    window.removeEventListener("favouriteToggled", this._onFavouriteToggled);
  }

  async handleScroll() {
    if (!this.mapLoaded && isInViewport(this.mapTarget)) {
      await this.initializeMap();
    }
  }

  async initializeMap() {
    this.initGoogleMaps();
    console.log('Google Maps API loaded:', window.google.maps);
    this.google = window.google;

    await this.initMap();
    this.mapLoaded = true;
  }

  async initMap() {
    const { Map } = await this.google.maps.importLibrary("maps");
    const { AdvancedMarkerElement, PinElement } = await this.google.maps.importLibrary("marker");
    this.AdvancedMarkerElement = AdvancedMarkerElement;
    this.PinElement = PinElement;

    let data;

    const listingsContainer = document.getElementById("listings");

    if (listingsContainer) {
      data = retrieveListingsData(listingsContainer);
    } else {
      data = await this.fetchMarkersData(this.markersPathValue);
    }

    // Read center and zoom from data attributes
    const center = {
      lat: data.center.lat,
      lng: data.center.lng,
    };
    const zoom = data.zoom;

    this.map = new Map(this.mapTarget, {
      center: center,
      zoom: zoom,
      mapId: "82adb2908a28f329",
    });
    
    this.map.addListener("click", this.handleMapClick);

    this.createMarkers(data, 'listings');

    if (this.addEventListenersValue) {
      this.map.addListener('dragend', this.handleMapInteraction);
      this.map.addListener('zoom_changed', this.handleMapInteraction);
    }

    class Popup extends this.google.maps.OverlayView {
      position;
      containerDiv;
      constructor(position, content) {
        super();
        this.position = position;
        content.classList.add("popup-bubble");
  
        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement("div");
  
        bubbleAnchor.classList.add("popup-bubble-anchor");
        bubbleAnchor.appendChild(content);
        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("popup-container");
        this.containerDiv.appendChild(bubbleAnchor);
        // Optionally stop clicks, etc., from bubbling up to the map.
        Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
      }
      /** Called when the popup is added to the map. */
      onAdd() {
        this.getPanes().floatPane.appendChild(this.containerDiv);
      }
      /** Called when the popup is removed from the map. */
      onRemove() {
        if (this.containerDiv.parentElement) {
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
      }
      /** Called each frame when the popup needs to draw itself. */
      draw() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(
          this.position,
        );
        // Hide the popup when it is far out of view.
        const display =
          Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
            ? "block"
            : "none";
  
        if (display === "block") {
          this.containerDiv.style.left = divPosition.x + "px";
          this.containerDiv.style.top = divPosition.y + "px";
        }
  
        if (this.containerDiv.style.display !== display) {
          this.containerDiv.style.display = display;
        }
      }
    }

    this.Popup = Popup;
  }

  toggleAllSchools(event) {
    event.preventDefault(); // Prevent the default link behavior
    this.selectSpanTarget.classList.toggle('d-none');
    this.deselectSpanTarget.classList.toggle('d-none');
    const isChecked = !this.schoolsCheckboxTargets.every(checkbox => checkbox.checked);
    this.schoolsCheckboxTargets.forEach(checkbox => checkbox.checked = isChecked);
    this.updateSchoolMarkers();
  }

  async updateSchoolMarkers() {
    clearMarkers(this.schoolMarkers);

    const anyChecked = this.schoolsCheckboxTargets.some(checkbox => checkbox.checked);

    if (anyChecked) {
      const form = this.element.querySelector("#schools-filter-form");
      if (!form) return;
      
      // Collect form data
      const formData = new FormData(form);
      const queryString = new URLSearchParams(formData).toString();
      
      // Construct the URL for fetching school markers
      const url = `/${window.currentLocale}/schools/markers?${queryString}`;
  
      this.schoolsData = await this.fetchMarkersData(url);
      this.createMarkers(this.schoolsData, 'schools');
    }
  }

  createMarkers(data, type) {
    if (data.data.length === 0) {
      return [];
    }

    const markerMap = this.getMarkerDictionary(type);
    clearMarkers(markerMap);

    let pin;
    const color = data.color;
    
    if (data.icon) {
      pin = this.createPin(data.icon, color);
    }

    return data.data.forEach(item => {
      let content;
      if (data.markerTemplate) {
        content = createDomNodeFromHtml(data.markerTemplate.replace('{{price}}', item.price).replace('{{id}}', item.id).replace('{{saved_class}}', item.savedClass));
      } else if (item.icon) {
        content = this.createPin(item.icon, item.color).element
      } else if (pin) {
        content = pin.element.cloneNode(true);
      }
      const marker = this.createMarker(item, type, content);
      marker.setMap(this.map);
      markerMap.set(item.id, marker);
    });
  }

  createMarker(data, type, content) {
    const marker = new this.AdvancedMarkerElement({
      position: { lat: data.lat, lng: data.lng },
      content: content,
      map: this.map
    });

    marker.addListener("gmp-click", async () => {
      if (!data.infoWindowDesktop || !data.infoWindowMobile) {
        try {
          const response = await fetch(
            `/${window.currentLocale}/${type}/${data.id}/map_details`,
          );
          const details = await response.json();
          data.infoWindowDesktop = details.infoWindowDesktop;
          data.infoWindowMobile = details.infoWindowMobile;
        } catch (error) {
          console.error("Error fetching map details:", error);
        }
      }

      this.openPopupOrSheet(data);
    });

    return marker;
  }

  createPin(icon, color) {
    return new this.PinElement({
      glyph: createDomNodeFromHtml(icon),
      background: color,
      borderColor: color
    });
  }

  getMarkerDictionary(type) {
    if (type === 'listings') {
      return this.listingMarkers;
    } else if (type === 'schools') {
      return this.schoolMarkers;
    } else {
      throw new Error(`Unknown marker type: ${type}`);
    }
  }

  async fetchMarkersData(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch markers data:", error);
      // Handle errors or rethrow them depending on your application structure
      return null; // Or you might want to return a default value or rethrow the error
    }
  }

  handleMapClick() {
    this.closeAllPopups();
  }

  async handleMapInteraction() {
    if (!this.previousBounds) {
      this.previousBounds = this.map.getBounds();
      return;
    }

    clearTimeout(this.mapInteractionTimeout);

    this.mapInteractionTimeout = setTimeout(async () => {    
      const currentBounds = this.map.getBounds();
      const boundsDiff = calculateBoundsDifference(this.previousBounds, currentBounds);

      if (boundsDiff < 0.00001) {
        return;
      }

      const boundsParams = formatMapBoundsParams(this.map);
      const listingsPath = `/${window.currentLocale}/listings?${boundsParams}`;

      fetchWithTurboStream(listingsPath);

      this.previousBounds = currentBounds;
    }, 300);
  }

  handleResize() {
    if (isMobile() !== this.isMobile) {
      this.closeAllPopups();
  
      this.isMobile = isMobile();
    }
  }

  async updateMarkers() {
    const listingsContainer = document.getElementById("listings");
    const data = retrieveListingsData(listingsContainer);

    if (data.autoCenter) {
      const center = {
        lat: data.center.lat,
        lng: data.center.lng,
      };
      const zoom = data.zoom

      this.map.setCenter(center);
      this.map.setZoom(zoom);
    }

    this.createMarkers(data, 'listings');

    this.updateSchoolMarkers();
  }

  async searchCleared() {
    const listingsPath = `/${window.currentLocale}/listings?search_cleared=true`;
    fetchWithTurboStream(listingsPath);
  }

  highlightMarker(event) {
    const id = parseInt(event.currentTarget.dataset.id, 10);
    const marker = this.listingMarkers.get(id);
    if (marker) {
      marker.zIndex = 999;
      marker.content.classList.replace('btn-white', 'btn-primary');
      marker.content.classList.add('highlighted');
    }
  }

  unhighlightMarker(event) {
    const id = parseInt(event.currentTarget.dataset.id, 10);
    const marker = this.listingMarkers.get(id);
    if (marker) {
      marker.zIndex = null;
      marker.content.classList.replace('btn-primary', 'btn-white');
      marker.content.classList.remove('highlighted');
    }
  }

  _onFavouriteToggled(event) {
    console.log(event.detail);
    const { listingId, isSaved } = event.detail;
    const marker = this.listingMarkers.get(listingId);
    if (!marker) return;

    const svgEl = marker.content.querySelector("svg");
    if (!svgEl) return;

    if (isSaved) {
      svgEl.classList.remove("d-none");
    } else {
      svgEl.classList.add("d-none");
    }
  }

  openPopupOrSheet(data) {
    if (isMobile()) {
      this.showMobileSheet(data);
    } else {
      this.showDesktopPopup(data);
    }
  }

  showDesktopPopup(data) {
    // If there is an existing popup, remove it
    if (this.popup) {
      this.popup.setMap(null);
    }
    // Create and open new popup
    this.popup = new this.Popup(
      new this.google.maps.LatLng(data.lat, data.lng), 
      createDomNodeFromHtml(data.infoWindowDesktop)
    );
    this.popup.setMap(this.map);
  }
  
  showMobileSheet(data) {
    // Also remove any existing popup, if you want to ensure
    // there's no leftover from desktop usage
    if (this.popup) {
      this.popup.setMap(null);
    }
    // Instead of an OverlayView-based popup, add content to bottom sheet
    const bottomSheet = document.getElementById("bottom-sheet");
    bottomSheet.innerHTML = data.infoWindowMobile;
    bottomSheet.classList.add("open");
  }

  closeAllPopups() {
    if (this.popup) {
      this.popup.setMap(null);
    }

    const bottomSheet = document.getElementById("bottom-sheet");
    if (bottomSheet) {
      bottomSheet.classList.remove("open");
    }
  }

  initGoogleMaps() {
    ((g) => {
      var h,
        a,
        k,
        p = "The Google Maps JavaScript API",
        c = "google",
        l = "importLibrary",
        q = "__ib__",
        m = document,
        b = window;
      b = b[c] || (b[c] = {});
      var d = b.maps || (b.maps = {}),
        r = new Set(),
        e = new URLSearchParams(),
        u = () =>
          h ||
          (h = new Promise(async (f, n) => {
            await (a = m.createElement("script"));
            e.set("libraries", [...r] + "");
            for (k in g)
              e.set(
                k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
                g[k],
              );
            e.set("callback", c + ".maps." + q);
            a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
            d[q] = f;
            a.onerror = () => (h = n(Error(p + " could not load.")));
            a.nonce = m.querySelector("script[nonce]")?.nonce || "";
            m.head.append(a);
          }));
      d[l]
        ? console.warn(p + " only loads once. Ignoring:", g)
        : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
    })({
      key: "AIzaSyB9qFVE2y-pmaoa1xc_gereM0QIAjOxTfs",
      v: "weekly",
      // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
      // Add other bootstrap parameters as needed, using camel case.
    });
  }
}
