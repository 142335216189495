import { Controller } from "@hotwired/stimulus";
import { getOrInitializeModal, openModal } from "../utils/modal_helpers";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    getOrInitializeModal();

    openModal();
  }
}
