import { Controller } from "@hotwired/stimulus";
import { fetchWithTurboStream } from "../utils/fetchUtils";
import { toggleButtonState, updateButtonState } from "../utils/buttons";

// Connects to data-controller="otp-sender"
export default class extends Controller {
  static targets = ["phoneNumber", "context", "submitButton"];

  static values = {
    errorMessage: String,
    displayOtpField: Boolean,
  };

  connect() {
    this.updateButtonStates();

    const otpInput = this.element.querySelector('input[name="otp_code_1"]');
    if (otpInput) {
      otpInput.focus();
    }

    this.startCountdown();

    if (this.hasErrorMessageValue) {
      this.handleError();
    } else if (this.displayOtpFieldValue) {
      document.getElementById("otp-field-container").classList.remove("d-none");
      document.getElementById("send-otp-button").classList.add("d-none");
      document.getElementById("otp-sent-text").classList.remove("d-none");
      document.getElementById("otp").focus();
    }
  }

  handleError() {
    updateButtonState(document.getElementById("send-otp-button"), false);
  }

  disableButton() {
    updateButtonState(this.submitButtonTarget, true);
  }

  async sendOtp() {
    const data = {
      user: { phone_number: this.phoneNumberTarget.value },
    };

    if (this.hasContextTarget) {
      data.context = this.contextTarget.value;
    }

    const body = JSON.stringify(data);
    const url = `/${window.currentLocale}/send_otp`;

    await fetchWithTurboStream(url, {
      method: "POST",
      body: body,
    });
  }

  startCountdown() {
    var countdown = 59;
    var otpCountdown = this.element.querySelector(".otp-countdown");

    if (otpCountdown) {
      var timer = otpCountdown.querySelector(".timer");
      var countdownMessage = otpCountdown.querySelector(".countdown-message");
      var resendMessage = otpCountdown.querySelector(".resend-message");

      countdownMessage.style.display = "block";
      resendMessage.style.display = "none";
      timer.textContent = "00:59";

      var countdownTimer = setInterval(function () {
        timer.textContent = "00:" + (countdown < 10 ? "0" : "") + countdown;
        countdown--;

        if (countdown < 0) {
          clearInterval(countdownTimer);
          countdownMessage.style.display = "none";
          resendMessage.style.display = "block";
        }
      }, 1000);
    }
  }

  resetOTPVerification() {
    document.getElementById("otp").value = "";
    document.getElementById("otp-field-container").classList.add("d-none");
    document.getElementById("otp-sent-text").classList.add("d-none");
    document.getElementById("otp-verified-text").classList.add("d-none");
    document.getElementById("send-otp-button").classList.remove("d-none");
    document.getElementById("phone-number-error-").textContent = "";
    updateButtonState(document.getElementById("send-otp-button"), false);
    updateButtonState(document.getElementById("verify-otp-button"), false);
  }

  updateButtonStates() {
    document
      .querySelectorAll('button[id^="send-otp-button"]')
      .forEach((button) => {
        toggleButtonState(button, false);
      });
  }
}
