import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="slider"
export default class extends Controller {
  static values = {
    start: Array,
    min: Number,
    max: Number,
    range: Object,
    divider: Number,
    suffix: String,
    digits: Number
  };

  static targets = ["slider", "minInput", "maxInput", "histogramBar", "minTooltip", "maxTooltip"];

  async connect() {
    const noUiSlider = await import("nouislider/dist/nouislider.mjs");

    noUiSlider.create(this.sliderTarget, {
      start: this.startValue,
      connect: true,
      range: this.rangeValue,
      format: this.format(),
    });

    this.sliderTarget.noUiSlider.on(
      "update",
      (rawValues, handle, unencoded) => {
        this.updateTooltips(rawValues);
        this.updateHistogram(unencoded[0], unencoded[1]);
      },
    );

    this.sliderTarget.noUiSlider.on(
      "set",
      (rawValues, handle, unencoded) => {
        this.updateValues(rawValues, unencoded);
      },
    );
  }

  updateValues(rawValues, unencoded) {
    this.minInputTarget.value = unencoded[0];
    this.maxInputTarget.value = unencoded[1];

    if (unencoded[0] === this.sliderTarget.noUiSlider.options.range.min[0]) {
      this.minInputTarget.value = ''; // Reset min input to empty
    }

    if (unencoded[1] === this.sliderTarget.noUiSlider.options.range.max[0]) {
      this.maxInputTarget.value = ''; // Reset max input to empty
    }

    const event = new Event("input", { bubbles: true, cancelable: true });
    this.minInputTarget.dispatchEvent(event);
    this.maxInputTarget.dispatchEvent(event);
  }

  disconnect() {
    if (this.sliderTarget.noUiSlider) {
      this.sliderTarget.noUiSlider.destroy();
    }
  }

  updateTooltips(rawValues) {
    if (this.hasMinTooltipTarget) {
      this.minTooltipTarget.textContent = rawValues[0];
    }
    if (this.hasMaxTooltipTarget) {
      this.maxTooltipTarget.textContent = rawValues[1];
    }
  }

  format() {
    const divider = this.dividerValue; // e.g. 100_000 or 1_000_000
    const suffix = this.suffixValue;   // e.g. "k", "M", or "万"
    const digits = this.digitsValue;   // e.g. "k", "M", or "万"

    return {
      to(value) {
        // Example: $500k => value=500,000 => (500,000 / 100,000) = 5 => "5k"
        // or if suffix="万" & divider=10_000 => (100,000 / 10,000)=10 => "10万"
        const number = value / divider;
        const formatted = number.toLocaleString("en-US", {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits
        });
        return `$${formatted}${suffix}`;
      },
      from(str) {
        // We remove `$`, spaces, or the suffix, then multiply back by divider
        // (You might need a more robust parse if suffix can be multiple chars or
        // has special symbols.)
        const numeric = parseFloat(str.replace(/[^0-9.]/g, ""));
        return numeric * divider;
      }
    };
  }

  updateHistogram(minPrice, maxPrice) {
    this.histogramBarTargets.forEach((bar) => {
      const priceMin = parseFloat(bar.dataset.priceMin);
      const priceMax = parseFloat(bar.dataset.priceMax);

      if (priceMin >= minPrice && priceMax <= maxPrice) {
        bar.classList.replace("bg-primary-subtle", "bg-primary");
      } else {
        bar.classList.replace("bg-primary", "bg-primary-subtle");
      }
    });
  }
}
