import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk"
export default class extends Controller {
  chooseAddToList(event) {
    event.preventDefault()
    const form = document.getElementById("contacts-form");

    // this.actionTarget.value = "add_to_list"
    form.requestSubmit()  // Submits the entire form
  }

  chooseRemove(event) {
    event.preventDefault()
    this.actionTarget.value = "remove"
    this.element.requestSubmit()
  }
}
