const translations = {
  en: {
    are_you_sure: "Are you sure?",
    yes: "Yes",
    no: "No",
    countries: {
      hk: "Hong Kong",
      cn: "China",
      mo: "Macau",
    },
  },
  "zh-HK": {
    are_you_sure: "您確定嗎？",
    yes: "是",
    no: "否",
    countries: {
      hk: "香港",
      cn: "中國",
      mo: "澳門",
    },
  },
  "zh-CN": {
    are_you_sure: "您确定吗？",
    yes: "是",
    no: "否",
    countries: {
      hk: "中国香港",
      cn: "中国",
      mo: "中国澳门",
    },
  },
};

export default translations;
